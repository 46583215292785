import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { FuturesMarket, FuturesMarketAsset } from '@dextoroprotocol/sdk/types'
import {
	getDisplayAsset,
	AssetDisplayByAsset,
	MarketKeyByAsset,
	formatDollars,
	getMarketName,
} from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import { useRouter } from 'next/router'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import FilterIcon from 'assets/svg/app/filter.svg'
import SearchIcon from 'assets/svg/app/search.svg'
import FavoriteIcon from 'assets/svg/futures/favorite-star.svg'
import TabFilterButton from 'components/Button/TabFilterButton'
import ChangePercent from 'components/ChangePercent'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { FlexDiv, FlexDivRowCentered } from 'components/layout/flex'
import MarketBadge from 'components/MarketBadge'
import SearchBarNew from 'components/SearchBar/SearchBarNew'
import Spacer from 'components/Spacer'
import { Body } from 'components/Text'

import { BANNER_HEIGHT_DESKTOP, BANNER_HEIGHT_MOBILE } from 'constants/announcement'
import { CurrencyName, CustomisedTickers } from 'constants/currency'
import ROUTES from 'constants/routes'
import { zIndex } from 'constants/ui'
import useClickOutside from 'hooks/useClickOutside'
import useLocalStorage from 'hooks/useLocalStorage'
import { selectShowBanner } from 'state/app/selectors'
import { selectFuturesType, selectMarketAsset } from 'state/futures/common/selectors'
import {
	selectMarkets,
	selectMarketInfo,
	selectMarkPriceInfos,
	selectMarketVolumes,
	selectSelectedInputHours,
} from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { showMobileTradePanel } from 'state/app/reducer'
import { selectPreviousDayPrices } from 'state/prices/selectors'
import media from 'styles/media'
import { getSynthDescription } from 'utils/futures'

import { convertMarketName } from 'utils/formatters/marketName'

import {
	MARKETS_DETAILS_HEIGHT_DESKTOP,
	TRADE_PANEL_WIDTH_LG,
	TRADE_PANEL_WIDTH_MD,
} from '../styles'

import MarketsDropdownSelector, { MARKET_SELECTOR_HEIGHT_MOBILE } from './MarketsDropdownSelector'
import MarketPanelDetailedView from './MarketPanelDetailedView'

type MarketsDropdownProps = {
	mobile?: boolean
	open: boolean
	setOpen: (value: boolean) => void
}

enum SortMethod {
	Favorites = 'favorites',
	SortByPrice = 'sortByPrice',
	SortByChange = 'sortByChange',
	SortByVolume = 'sortByVolume',
	SortByOP = 'sortByOP',
	SortByFR = 'sortByFR',
}

enum SortOrder {
	Ascending = 'asc',
	Descending = 'desc',
}

const MarketsDropdown: React.FC<MarketsDropdownProps> = ({ mobile, open, setOpen }) => {
	const markPrices = useAppSelector(selectMarkPriceInfos)
	const pastPrices = useAppSelector(selectPreviousDayPrices)
	const accountType = useAppSelector(selectFuturesType)
	const showBanner = useAppSelector(selectShowBanner)
	const marketAsset = useAppSelector(selectMarketAsset)
	const futuresMarkets = useAppSelector(selectMarkets)
	const futuresVolumes = useAppSelector(selectMarketVolumes)
	const fundingHours = useAppSelector(selectSelectedInputHours)
	const [searchOpen, setSearchOpen] = useState(false)
	const [activeFilter, setActiveFilter] = useState<string>('all')
	const marketInfo = useAppSelector(selectMarketInfo)
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState(false)
	const [detailsCard, setDetailsCard] = useState(false)
	const [sortMethod, setSortMethod] = useState<string>(SortMethod.Favorites)
	const [opDirection, setopDirection] = useState<string>(SortOrder.Descending)
	const [frDirection, setfrDirection] = useState<string>(SortOrder.Descending)
	const [priceDirection, setPriceDirection] = useState<string>(SortOrder.Descending)
	const [changeDirection, setChangeDirection] = useState<string>(SortOrder.Descending)
	const [volumeDirection, setVolumeDirection] = useState<string>(SortOrder.Descending)
	const [favMarkets, setFavMarkets] = useLocalStorage<string[]>('favorite-markets', [])

	const { ref } = useClickOutside(() => setOpen(false))

	const router = useRouter()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const onClearSearch = useCallback(() => {
		setSearch('')
		setSearchOpen(false)
	}, [setSearch])

	const onSelectFav = useCallback(
		(asset: string) => {
			const index = favMarkets.indexOf(asset)

			if (index !== -1) {
				favMarkets.splice(index, 1)
			} else {
				favMarkets.push(asset)
			}
			setFavMarkets([...favMarkets])
		},
		[favMarkets, setFavMarkets]
	)

	const getBasePriceRateInfo = useCallback(
		(asset: FuturesMarketAsset) => {
			return markPrices[MarketKeyByAsset[asset]]
		},
		[markPrices]
	)

	const getPastPrice = useCallback(
		(asset: string) =>
			pastPrices.find((price) => price.synth === getDisplayAsset(asset)?.toUpperCase()),
		[pastPrices]
	)

	const onSelectMarket = useCallback(
		(asset: string) => {
			router.push(ROUTES.Markets.MarketPair(asset, accountType))
			setOpen(false)
		},
		[accountType, router]
	)

	const selectedBasePriceRate = getBasePriceRateInfo(marketAsset)
	const selectedPastPrice = getPastPrice(marketAsset)

	const options = useMemo(() => {
		const lowerSearch = search?.toLowerCase()
		const markets = lowerSearch
			? (futuresMarkets as FuturesMarket[]).filter(
					(m) =>
						m.asset.toLowerCase().includes(lowerSearch) ||
						AssetDisplayByAsset[m.asset]?.toLocaleLowerCase().includes(lowerSearch)
			  )
			: futuresMarkets

		const sortedMarkets = markets
			.filter((m) => favMarkets.includes(m.asset))
			.sort((a, b) =>
				getBasePriceRateInfo(b.asset)
					?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
					.gt(0)
					? 1
					: -1
			)
			.concat(
				markets
					.filter((m) => !favMarkets.includes(m.asset))
					.sort((a, b) =>
						getBasePriceRateInfo(b.asset)
							?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
							.gt(0)
							? 1
							: -1
					)
			)

		if (activeFilter === 'forex') {
			let filterOutArray = ['XAU', 'XAG', 'EUR', 'GBP', 'AUD']
			const getForexMarket = markets
				.filter((m) => filterOutArray.includes(m.asset))
				.sort((a, b) =>
					getBasePriceRateInfo(b.asset)
						?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
						.gt(0)
						? 1
						: -1
				)

			const forexMarkets = getForexMarket
				.filter((m) => favMarkets.includes(m.asset))
				.concat(getForexMarket.filter((m) => !favMarkets.includes(m.asset)))

			return forexMarkets.map((market) => {
				const pastPrice = getPastPrice(market.asset)
				const basePriceRate = getBasePriceRateInfo(market.asset)

				const change =
					basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
						? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
						: ZERO_WEI
				const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

				return {
					value: market.asset,
					label: convertMarketName(getMarketName(market.asset)),
					asset: market.asset,
					key: market.marketKey,
					description: getSynthDescription(market.asset, t),
					priceNum: basePriceRate?.price.toNumber() ?? 0,
					price: formatDollars(basePriceRate?.price ?? '0', {
						suggestDecimalsForAsset: market.asset,
					}),
					change: change.toNumber(),
					priceDirection: basePriceRate?.change ?? null,
					isMarketClosed: market.isSuspended,
					closureReason: market.marketClosureReason,
					fundingRate: hourlyFundingRate.toNumber() ?? 0,
					dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
					openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
					appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
				}
			})
		}
		if (activeFilter === 'defi') {
			let filterOutArray = [
				'YFI',
				'MKR',
				'AAVE',
				'COMP',
				'GMX',
				'INJ',
				'LINK',
				'RPL',
				'FXS',
				'UNI',
				'BAL',
				'DYDX',
				'LDO',
				'UMA',
				'SUSHI',
				'KNC',
				'PERP',
				'CRV',
				'1INCH',
				'PYTH',
				'TRB',
				'GRT',
				'JTO',
				'JUP',
				'CVX',
			]
			const getForexMarket = markets
				.filter((m) => filterOutArray.includes(m.asset))
				.sort((a, b) =>
					getBasePriceRateInfo(b.asset)
						?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
						.gt(0)
						? 1
						: -1
				)

			const defiMarkets = getForexMarket
				.filter((m) => favMarkets.includes(m.asset))
				.concat(getForexMarket.filter((m) => !favMarkets.includes(m.asset)))

			return defiMarkets.map((market) => {
				const pastPrice = getPastPrice(market.asset)
				const basePriceRate = getBasePriceRateInfo(market.asset)

				const change =
					basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
						? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
						: ZERO_WEI
				const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

				return {
					value: market.asset,
					label: convertMarketName(getMarketName(market.asset)),
					asset: market.asset,
					key: market.marketKey,
					description: getSynthDescription(market.asset, t),
					priceNum: basePriceRate?.price.toNumber() ?? 0,
					price: formatDollars(basePriceRate?.price ?? '0', {
						suggestDecimalsForAsset: market.asset,
					}),
					change: change.toNumber(),
					priceDirection: basePriceRate?.change ?? null,
					isMarketClosed: market.isSuspended,
					closureReason: market.marketClosureReason,
					fundingRate: hourlyFundingRate.toNumber() ?? 0,
					dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
					openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
					appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
				}
			})
		}
		return sortedMarkets.map((market) => {
			const pastPrice = getPastPrice(market.asset)
			const basePriceRate = getBasePriceRateInfo(market.asset)

			const change =
				basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
					? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
					: ZERO_WEI
			const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

			return {
				value: market.asset,
				label: convertMarketName(getMarketName(market.asset)),
				asset: market.asset,
				key: market.marketKey,
				description: getSynthDescription(market.asset, t),
				priceNum: basePriceRate?.price.toNumber() ?? 0,
				price: formatDollars(basePriceRate?.price ?? '0', {
					suggestDecimalsForAsset: market.asset,
				}),
				change: change.toNumber(),
				priceDirection: basePriceRate?.change ?? null,
				isMarketClosed: market.isSuspended,
				closureReason: market.marketClosureReason,
				fundingRate: hourlyFundingRate.toNumber() ?? 0,
				dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
				openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
				appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
			}
		})
	}, [search, futuresMarkets, favMarkets, getPastPrice, getBasePriceRateInfo, t, activeFilter])

	const handleSort = useCallback(
		(
			sortBy: string,
			priceDirection?: string,
			changeDirection?: string,
			opDirection?: string,
			frDirection?: string,
			volumeDirection?: string
		) => {
			// Sort by Price
			if (sortBy === SortMethod.SortByPrice) {
				return priceDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.priceNum - a.priceNum)
					: priceDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.priceNum - b.priceNum)
					: [...options].sort((a, b) => b.priceNum - a.priceNum)
			}

			// Sort by Change
			else if (sortBy === SortMethod.SortByChange) {
				return changeDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.change - a.change)
					: changeDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.change - b.change)
					: [...options].sort((a, b) => b.change - a.change)
			}

			// Sort by Volume
			else if (sortBy === SortMethod.SortByVolume) {
				return volumeDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.dailyVolume - a.dailyVolume)
					: volumeDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.dailyVolume - b.dailyVolume)
					: [...options].sort((a, b) => b.dailyVolume - a.dailyVolume)
			}

			// Sort by Open Interest
			if (sortBy === SortMethod.SortByOP) {
				return opDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.openInterest - a.openInterest)
					: opDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.openInterest - b.openInterest)
					: [...options].sort((a, b) => b.openInterest - a.openInterest)
			}

			// Sort by Funding Rate
			if (sortBy === SortMethod.SortByFR) {
				return frDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.fundingRate - a.fundingRate)
					: frDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.fundingRate - b.fundingRate)
					: [...options].sort((a, b) => b.fundingRate - a.fundingRate)
			} else {
				return options
			}
		},
		[options]
	)

	const marketData = useMemo(() => {
		return handleSort(
			sortMethod,
			priceDirection,
			changeDirection,
			opDirection,
			frDirection,
			volumeDirection
		)
	}, [
		sortMethod,
		priceDirection,
		changeDirection,
		opDirection,
		frDirection,
		volumeDirection,
		handleSort,
	])

	const tableHeight: number = useMemo(() => {
		const BANNER_HEIGHT = mobile ? BANNER_HEIGHT_MOBILE : BANNER_HEIGHT_DESKTOP
		const OFFSET = mobile ? 159 : 127
		return Math.max(window.innerHeight - OFFSET - Number(showBanner) * BANNER_HEIGHT, 300)
	}, [mobile, showBanner])

	return (
		<SelectContainer mobile={mobile} ref={ref} accountType={accountType}>
			{!mobile && (
				<MarketsDropdownSelector
					onClick={() => setOpen(!open)}
					expanded={open}
					mobile={mobile}
					asset={marketAsset}
					label={convertMarketName(
						getMarketName(marketAsset)
							?.replace(/\//, '-')
							.replace(/sUSD/, 'USD')
							.replace(/sUSD/g, 'USD')
					)}
					description={getSynthDescription(marketAsset, t)}
					isMarketClosed={marketInfo?.isSuspended}
					closureReason={marketInfo?.marketClosureReason}
					priceDetails={{
						oneDayChange:
							selectedBasePriceRate?.price &&
							selectedPastPrice?.rate &&
							selectedBasePriceRate.price.gt(0)
								? wei(selectedBasePriceRate.price)
										.sub(selectedPastPrice.rate)
										.div(selectedBasePriceRate.price)
								: ZERO_WEI,
						priceInfo: selectedBasePriceRate,
					}}
				/>
			)}
			{!open && mobile && (
				<MarketsDropdownSelector
					onClick={() => dispatch(showMobileTradePanel(true))}
					expanded={open}
					mobile={mobile}
					asset={marketAsset}
					label={convertMarketName(
						getMarketName(marketAsset)
							?.replace(/\//, '-')
							.replace(/sUSD/, 'USD')
							.replace(/sUSD/g, 'USD')
					)}
					description={getSynthDescription(marketAsset, t)}
					isMarketClosed={marketInfo?.isSuspended}
					closureReason={marketInfo?.marketClosureReason}
					priceDetails={{
						oneDayChange:
							selectedBasePriceRate?.price &&
							selectedPastPrice?.rate &&
							selectedBasePriceRate.price.gt(0)
								? wei(selectedBasePriceRate.price)
										.sub(selectedPastPrice.rate)
										.div(selectedBasePriceRate.price)
								: ZERO_WEI,
						priceInfo: selectedBasePriceRate,
					}}
				/>
			)}
			{open && (
				<MarketsList mobile={mobile} height={tableHeight}>
					<ButtonDiv>
						<TabButtonDiv>
							<ButtonFilter
								inline={true}
								title={'All'}
								active={activeFilter === 'all' ? true : false}
								badgeCount={0}
								disabled={true}
								onClick={() => setActiveFilter('all')}
							></ButtonFilter>

							<ButtonFilter
								inline={true}
								title={'Forex'}
								active={activeFilter === 'forex' ? true : false}
								badgeCount={0}
								disabled={true}
								onClick={() => setActiveFilter('forex')}
							></ButtonFilter>

							<ButtonFilter
								inline={true}
								title={'DeFi'}
								active={activeFilter === 'defi' ? true : false}
								badgeCount={0}
								disabled={true}
								onClick={() => setActiveFilter('defi')}
							></ButtonFilter>
						</TabButtonDiv>

						<SearchIconDiv>
							<FilterIconStyle filterSelected={filter} onClick={() => setFilter(!filter)} />
							<SearchIconStyle onClick={() => setSearchOpen(true)} height={13} width={13} />
							{filter && (
								<FilterMenu>
									<SortBox>
										<SortHeader>Sort By</SortHeader>
										<div>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.Favorites)
													handleSort(sortMethod)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.Favorites} />
												<FilterLabel>Favorites</FilterLabel>
											</FilterButton>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.SortByPrice)
													handleSort(sortMethod, priceDirection)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.SortByPrice} />
												<FilterLabel>Price</FilterLabel>
											</FilterButton>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.SortByVolume)
													handleSort(sortMethod, volumeDirection)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.SortByVolume} />
												<FilterLabel>24h Volume</FilterLabel>
											</FilterButton>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.SortByChange)
													handleSort(sortMethod, changeDirection)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.SortByChange} />
												<FilterLabel>24h Change</FilterLabel>
											</FilterButton>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.SortByOP)
													handleSort(sortMethod, opDirection)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.SortByOP} />
												<FilterLabel>Open Interest</FilterLabel>
											</FilterButton>
											<FilterButton
												onClick={() => {
													setSortMethod(SortMethod.SortByFR)
													handleSort(sortMethod, frDirection)
												}}
											>
												<FilterInput selected={sortMethod === SortMethod.SortByFR} />
												<FilterLabel>Funding Rate</FilterLabel>
											</FilterButton>
										</div>
									</SortBox>
									{sortMethod === SortMethod.SortByPrice && (
										<SortBox>
											<SortHeader>Direction</SortHeader>
											<div>
												<FilterButton onClick={() => setPriceDirection(SortOrder.Descending)}>
													<FilterInput selected={priceDirection === SortOrder.Descending} />
													<FilterLabel>Decreasing</FilterLabel>
												</FilterButton>
												<FilterButton onClick={() => setPriceDirection(SortOrder.Ascending)}>
													<FilterInput selected={priceDirection === SortOrder.Ascending} />
													<FilterLabel>Increasing</FilterLabel>
												</FilterButton>
											</div>
										</SortBox>
									)}
									{sortMethod === SortMethod.SortByVolume && (
										<SortBox>
											<SortHeader>Direction</SortHeader>
											<div>
												<FilterButton onClick={() => setVolumeDirection(SortOrder.Descending)}>
													<FilterInput selected={volumeDirection === SortOrder.Descending} />
													<FilterLabel>Decreasing</FilterLabel>
												</FilterButton>
												<FilterButton onClick={() => setVolumeDirection(SortOrder.Ascending)}>
													<FilterInput selected={volumeDirection === SortOrder.Ascending} />
													<FilterLabel>Increasing</FilterLabel>
												</FilterButton>
											</div>
										</SortBox>
									)}
									{sortMethod === SortMethod.SortByChange && (
										<SortBox>
											<SortHeader>Direction</SortHeader>
											<div>
												<FilterButton onClick={() => setChangeDirection(SortOrder.Descending)}>
													<FilterInput selected={changeDirection === SortOrder.Descending} />
													<FilterLabel>Decreasing</FilterLabel>
												</FilterButton>
												<FilterButton onClick={() => setChangeDirection(SortOrder.Ascending)}>
													<FilterInput selected={changeDirection === SortOrder.Ascending} />
													<FilterLabel>Increasing</FilterLabel>
												</FilterButton>
											</div>
										</SortBox>
									)}
									{sortMethod === SortMethod.SortByOP && (
										<SortBox>
											<SortHeader>Direction</SortHeader>
											<div>
												<FilterButton onClick={() => setopDirection(SortOrder.Descending)}>
													<FilterInput selected={opDirection === SortOrder.Descending} />
													<FilterLabel>Decreasing</FilterLabel>
												</FilterButton>
												<FilterButton onClick={() => setopDirection(SortOrder.Ascending)}>
													<FilterInput selected={opDirection === SortOrder.Ascending} />
													<FilterLabel>Increasing</FilterLabel>
												</FilterButton>
											</div>
										</SortBox>
									)}
									{sortMethod === SortMethod.SortByFR && (
										<SortBox>
											<SortHeader>Direction</SortHeader>
											<div>
												<FilterButton onClick={() => setfrDirection(SortOrder.Descending)}>
													<FilterInput selected={frDirection === SortOrder.Descending} />
													<FilterLabel>Decreasing</FilterLabel>
												</FilterButton>
												<FilterButton onClick={() => setfrDirection(SortOrder.Ascending)}>
													<FilterInput selected={frDirection === SortOrder.Ascending} />
													<FilterLabel>Increasing</FilterLabel>
												</FilterButton>
											</div>
										</SortBox>
									)}
									<SortBox>
										<SortHeader>Cards</SortHeader>
										<FilterCheckBox onClick={() => setDetailsCard(!detailsCard)}>
											<CheckBox checked={detailsCard} />
											<FilterLabelCard>Detailed view</FilterLabelCard>
										</FilterCheckBox>
									</SortBox>
								</FilterMenu>
							)}
						</SearchIconDiv>
						<SearchBarContainer searchOpen={searchOpen}>
							<SearchBarNew
								autoFocus
								onChange={setSearch}
								value={search}
								border={false}
								placeHolder={`e.g. “ETH” or “Ethereum”`}
								onClear={onClearSearch}
							/>
						</SearchBarContainer>
					</ButtonDiv>
					{marketData.length > 0 ? (
						!detailsCard ? (
							<TableContainer>
								{marketData.map((row) => (
									<StyledTableDiv
										onClick={() => {
											if (filter) {
												setFilter(false)
											} else {
												onSelectMarket(row.asset)
											}
										}}
										selected={router.asPath.includes(row.asset)}
									>
										<CurrencyContainer>
											<FavoriteIconWrapper
												onClick={(e) => {
													onSelectFav(row.asset)
													e.stopPropagation()
												}}
											>
												{favMarkets.includes(row.asset) ? (
													<StyledSelectedIcon height={14} width={15} />
												) : (
													<StyledFavoriteIcon height={14} width={15} />
												)}
											</FavoriteIconWrapper>
											<CurrencyIcon currencyKey={row.key} width={35} height={35} />
											<Body type="span" size="large" style={{ fontSize: '15px' }}>
												{CurrencyName[row.key] || getDisplayAsset(row.asset)}
											</Body>
											<Spacer width={4} />
											<StyledButton>
												{getDisplayAsset(CustomisedTickers[row.key] || row.asset)}
											</StyledButton>
										</CurrencyContainer>
										<BadgeContainer>
											<Body
												type="span"
												size="large"
												style={{ fontSize: '15px', lineHeight: '20px' }}
											>
												{row.price}
											</Body>
											<FlexLeverageDiv>
												<MarketBadge
													currencyKey={row.asset}
													isFuturesMarketClosed={row.isMarketClosed}
													futuresClosureReason={row.closureReason}
													fallbackComponent={
														<StyledChangePercent
															value={row.change}
															decimals={2}
															className="change-pct"
														/>
													}
												/>
												<Spacer width={4} />
												<MaxLeverageButton>
													{row?.appMaxLeverage ? `${row.appMaxLeverage}×` : null}
												</MaxLeverageButton>
											</FlexLeverageDiv>
										</BadgeContainer>
									</StyledTableDiv>
								))}
							</TableContainer>
						) : (
							<MarketPanelDetailedView
								data={marketData}
								favMarkets={favMarkets}
								onSelectFav={onSelectFav}
								onSelectMarket={onSelectMarket}
								filter={filter}
								setFilter={setFilter}
							/>
						)
					) : (
						activeFilter === 'forex' && <NoResultDiv>Forex Pairs coming soon</NoResultDiv>
					)}
				</MarketsList>
			)}
		</SelectContainer>
	)
}

const NoResultDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	margin: 24px;
	font-size: 15px;
	border-radius: 8px;
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
`

const CurrencyContainer = styled(FlexDivRowCentered)`
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: left;
	width: 1000px;
	justify-content: flex-start;
`

const BadgeContainer = styled(FlexDivRowCentered)`
	text-align: right;
	width: 100%;
	justify-content: flex-end;
	padding-top: 4px;
	padding-bottom: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: end;
	font-size: 13px;
`

const StyledChangePercent = styled(ChangePercent)`
	font-family: ${(props) => props.theme.fonts.regular};
`

const MarketsList = styled.div<{ mobile?: boolean; height: number }>`
	top: 66px;
	z-index: 1000;
	backdrop-filter: blur(10px);
	height: ${(props) => props.height}px;
	width: ${TRADE_PANEL_WIDTH_LG}px;
	${media.lessThan('xxl')`
		width: ${TRADE_PANEL_WIDTH_MD}px;
	`}

	${media.lessThan('md')`
		width: 100%;
	`}

	border-top: ${(props) => props.theme.colors.selectedTheme.border};
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	${(props) =>
		props.mobile &&
		css`
			width: 100%;
		`}
`

const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding: 0 16px;
	height: 60px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
`

const ButtonFilter = styled(TabFilterButton)`
	margin-right: 10px;
`

const TabButtonDiv = styled.div`
	display: flex;
	margin-right: auto;
	gap: 8px;
`

const TableContainer = styled.div`
	height: 100%;
	padding-bottom: 59px;
	overflow: scroll;
	background-color: ${(props) =>
		props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	${media.lessThan('md')`height: 97%`}
`

const StyledTableDiv = styled.div<{ selected?: boolean }>`
	border: none;
	display: flex;
	cursor: pointer;
	background-color: ${(props) =>
		props.selected
			? props.theme.colors.selectedTheme.fill
			: props.theme.colors.selectedTheme.newTheme.containers.primary.background};
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding: 0 8.5px 0px 4px;
	overflow: hidden;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.gray3};
	}
`

const SearchBarContainer = styled.div<{ searchOpen: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	transition: min-width 0.45s cubic-bezier(0.16, 1, 0.3, 1);
	margin-left: ${(props) => (props.searchOpen ? '24px' : '0')};
	min-width: ${(props) => (props.searchOpen ? '100%' : '0')};
	max-width: ${(props) => (props.searchOpen ? 'none' : '0')};
	opacity: ${(props) => (props.searchOpen ? '1' : '0')};
`

const SelectContainer = styled.div<{ mobile?: boolean; accountType?: string }>`
	z-index: ${(props) => (props.mobile ? 50 : zIndex.MARKET_DROPDOWN)};
	height: ${MARKETS_DETAILS_HEIGHT_DESKTOP}px;
	position: fixed;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};

	${(props) =>
		props.mobile &&
		css`
			width: 100%;
			border-bottom: ${props.theme.colors.selectedTheme.border};
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: ${MARKET_SELECTOR_HEIGHT_MOBILE + 1}px;
		`}
`

const SearchIconStyle = styled(SearchIcon)`
	margin-top: 0px;
	cursor: pointer;
	height: 32px;
	width: 32px;
	border-radius: 50%;
	padding: 8px;
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`
const FilterIconStyle = styled(FilterIcon)<{ filterSelected?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px;
	width: 32px;
	height: 32px;
	border-radius: 8px;
	margin-left: 12px;
	cursor: pointer;

	${(props) =>
		props.filterSelected &&
		css`
			background: ${(props) => props.theme.colors.selectedTheme.button.fill};
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
			}
		`}

	${(props) =>
		props.filterSelected === false &&
		css`
			&:hover {
				background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
				path {
					stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
				}
			}
		`}
`

const SearchIconDiv = styled.div`
	display: flex;
	margin-right: auto;
	width: 50%;
	height: 100%;
	gap: 10px;
	align-items: center;
	justify-content: end;
`

export const MaxLeverageButton = styled.div`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 10px;
	padding: 1.5px 3px 0px 3px;

	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	border: ${(props) => props.theme.colors.selectedTheme.outlineBorder};
	border-radius: 4px;
	font-feature-settings: 'zero' 0;
`
export const FlexLeverageDiv = styled(FlexDiv)`
	align-items: center !important;
	${MaxLeverageButton} {
		padding: 0px 3px !important;
	}
`
const StyledButton = styled.div`
	font-family: ${(props) => props.theme.fonts.regular};
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	display: inline-flex;
	padding: 1px 4px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	border-radius: 2px;
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	text-transform: uppercase;
	letter-spacing: 0.06em;
`

const FilterMenu = styled.div`
	filter: drop-shadow(0 0 44px rgba(23, 23, 34, 0.2));
	position: absolute;
	top: 54px;
	right: 12px;
	padding: 12px 24px 12px 16px;
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
	width: auto;
	border-radius: 6px;
	z-index: 1;
	margin-left: 12px;
`
const SortBox = styled.div`
	width: 100%;
	margin-bottom: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
`
const SortHeader = styled.div`
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 10px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`
const FilterButton = styled.a`
	display: flex;
	margin-top: 10px;
	cursor: pointer;
`

const FilterCheckBox = styled.div`
	display: flex;
	position: relative;
	align-items: center;
`

const FilterInput = styled.div<{ selected?: boolean }>`
	display: flex;
	position: relative;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.selectedTheme.table.fill};
	&:hover {
		outline: 0.5px solid ${(props) => props.theme.colors.selectedTheme.outline};
	}

	${(props) =>
		props.selected &&
		css`
			&:before {
				position: absolute;
				top: 4px;
				left: 4px;
				content: '';
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background-color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
			}
		`}
`

const CheckBox = styled.span<{ checked?: boolean }>`
	position: absolute;
	top: calc(50% - 10px);
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 4px;
	z-index: 0;
	cursor: pointer;
	background-color: ${(props) => props.theme.colors.selectedTheme.fill};
	&:hover {
		outline: 0.5px solid ${(props) => props.theme.colors.selectedTheme.outline};
	}

	${(props) =>
		props.checked &&
		css`
			&:after {
				position: absolute;
				content: '';
				top: 4px;
				left: 7px;
				width: 5px;
				height: 8px;
				border: solid ${(props) => props.theme.colors.selectedTheme.primary};
				border-width: 0 2px 2px 0;
				border-radius: 1px;
				opacity: 1;
				transform: rotate(40deg) scale(1);
				transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
			}
		`}
`

const FilterLabel = styled.div`
	font-size: 14px;
	line-height: 18px;
	margin-left: 10px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`

const FilterLabelCard = styled(FilterLabel)`
	display: flex;
	align-items: center;
	margin-left: 0px;
	padding-left: 30px;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
`

const FavoriteIconWrapper = styled.div`
	cursor: 'pointer';
	margin: 0;
	width: 22px !important;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	&:hover {
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

const StyledSelectedIcon = styled(FavoriteIcon)`
	path {
		fill: #ffb648;
		stroke: #ffb648;
	}
`
const StyledFavoriteIcon = styled(FavoriteIcon)`
	path {
		stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	}

	&:hover {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

export default MarketsDropdown
