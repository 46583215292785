import React, { FC, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { FuturesMarket, FuturesMarketAsset } from '@dextoroprotocol/sdk/types'
import {
	getDisplayAsset,
	AssetDisplayByAsset,
	MarketKeyByAsset,
	formatDollars,
	getMarketName,
} from '@dextoroprotocol/sdk/utils'
import { wei } from '@synthetixio/wei'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import TabFilterButton from 'components/Button/TabFilterButton'
import { zIndex } from 'constants/ui'

import FilterIcon from 'assets/svg/app/filter.svg'
import SearchIcon from 'assets/svg/app/search.svg'
import FavoriteIcon from 'assets/svg/futures/favorite-star.svg'
import ChangePercent from 'components/ChangePercent'
import CurrencyIcon from 'components/Currency/CurrencyIcon'
import { FlexDiv, FlexDivCentered } from 'components/layout/flex'
import MarketBadge from 'components/MarketBadge'
import SearchBarNew from 'components/SearchBar/SearchBarNew'
import { Body } from 'components/Text'

import { CurrencyName, CustomisedTickers } from 'constants/currency'
import ROUTES from 'constants/routes'
import useClickOutside from 'hooks/useClickOutside'
import useLocalStorage from 'hooks/useLocalStorage'
import { selectFuturesType } from 'state/futures/common/selectors'
import {
	selectMarkets,
	selectMarketVolumes,
	selectMarkPriceInfos,
	selectSelectedInputHours,
} from 'state/futures/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { selectPreviousDayPrices } from 'state/prices/selectors'
import { getSynthDescription } from 'utils/futures'
import { convertMarketName } from 'utils/formatters/marketName'
import { showMobileTradePanel } from 'state/app/reducer'
import MarketPanelDetailedView from './MarketPanelDetailedView'
import Connector from 'containers/Connector'
import useIsL2 from 'hooks/useIsL2'
import { selectShowModal } from 'state/app/selectors'
import FuturesUnsupportedNetwork from './FuturesUnsupported'
import Spacer from 'components/Spacer'
import { FlexLeverageDiv, MaxLeverageButton } from './MarketsDropdown'

enum SortMethod {
	Favorites = 'favorites',
	SortByPrice = 'sortByPrice',
	SortByChange = 'sortByChange',
	SortByVolume = 'sortByVolume',
	SortByOP = 'sortByOP',
	SortByFR = 'sortByFR',
}

enum SortOrder {
	Ascending = 'asc',
	Descending = 'desc',
}

const MobileTradePanel: FC = () => {
	const { walletAddress } = Connector.useContainer()
	const isL2 = useIsL2()
	const openModal = useAppSelector(selectShowModal)
	const markPrices = useAppSelector(selectMarkPriceInfos)
	const pastPrices = useAppSelector(selectPreviousDayPrices)
	const accountType = useAppSelector(selectFuturesType)
	const futuresMarkets = useAppSelector(selectMarkets)
	const futuresVolumes = useAppSelector(selectMarketVolumes)
	const fundingHours = useAppSelector(selectSelectedInputHours)
	const [searchOpen, setSearchOpen] = useState(false)
	const [activeFilter, setActiveFilter] = useState<string>('all')
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState(false)
	const [detailsCard, setDetailsCard] = useState(false)
	const [sortMethod, setSortMethod] = useState<string>(SortMethod.Favorites)
	const [opDirection, setopDirection] = useState<string>(SortOrder.Descending)
	const [frDirection, setfrDirection] = useState<string>(SortOrder.Descending)
	const [priceDirection, setPriceDirection] = useState<string>(SortOrder.Descending)
	const [changeDirection, setChangeDirection] = useState<string>(SortOrder.Descending)
	const [volumeDirection, setVolumeDirection] = useState<string>(SortOrder.Descending)
	const [favMarkets, setFavMarkets] = useLocalStorage<string[]>('favorite-markets', [])

	const { ref } = useClickOutside(() => setFilter(!filter))

	const router = useRouter()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const onClearSearch = useCallback(() => {
		setSearch('')
		setSearchOpen(false)
	}, [setSearch])

	const onSelectFav = useCallback(
		(asset: string) => {
			const index = favMarkets.indexOf(asset)

			if (index !== -1) {
				favMarkets.splice(index, 1)
			} else {
				favMarkets.push(asset)
			}
			setFavMarkets([...favMarkets])
		},
		[favMarkets, setFavMarkets]
	)

	const getBasePriceRateInfo = useCallback(
		(asset: FuturesMarketAsset) => {
			return markPrices[MarketKeyByAsset[asset]]
		},
		[markPrices]
	)

	const getPastPrice = useCallback(
		(asset: string) =>
			pastPrices.find((price) => price.synth === getDisplayAsset(asset)?.toUpperCase()),
		[pastPrices]
	)

	const onSelectMarket = useCallback(
		(asset: string) => {
			router.push(ROUTES.Markets.MarketPair(asset, accountType))
			dispatch(showMobileTradePanel(false))
		},
		[accountType, router]
	)

	const options = useMemo(() => {
		const lowerSearch = search?.toLowerCase()
		const markets = lowerSearch
			? (futuresMarkets as FuturesMarket[]).filter(
					(m) =>
						m.asset.toLowerCase().includes(lowerSearch) ||
						AssetDisplayByAsset[m.asset]?.toLocaleLowerCase().includes(lowerSearch)
			  )
			: futuresMarkets

		const sortedMarkets = markets
			.filter((m) => favMarkets.includes(m.asset))
			.sort((a, b) =>
				getBasePriceRateInfo(b.asset)
					?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
					.gt(0)
					? 1
					: -1
			)
			.concat(
				markets
					.filter((m) => !favMarkets.includes(m.asset))
					.sort((a, b) =>
						getBasePriceRateInfo(b.asset)
							?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
							.gt(0)
							? 1
							: -1
					)
			)

		if (activeFilter === 'forex') {
			let filterOutArray = ['XAU', 'XAG', 'EUR', 'GBP', 'AUD']
			const getForexMarket = markets
				.filter((m) => filterOutArray.includes(m.asset))
				.sort((a, b) =>
					getBasePriceRateInfo(b.asset)
						?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
						.gt(0)
						? 1
						: -1
				)

			const forexMarkets = getForexMarket
				.filter((m) => favMarkets.includes(m.asset))
				.concat(getForexMarket.filter((m) => !favMarkets.includes(m.asset)))

			return forexMarkets.map((market) => {
				const pastPrice = getPastPrice(market.asset)
				const basePriceRate = getBasePriceRateInfo(market.asset)

				const change =
					basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
						? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
						: ZERO_WEI
				const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

				return {
					value: market.asset,
					label: convertMarketName(getMarketName(market.asset)),
					asset: market.asset,
					key: market.marketKey,
					description: getSynthDescription(market.asset, t),
					priceNum: basePriceRate?.price.toNumber() ?? 0,
					price: formatDollars(basePriceRate?.price ?? '0', {
						suggestDecimalsForAsset: market.asset,
					}),
					change: change.toNumber(),
					priceDirection: basePriceRate?.change ?? null,
					isMarketClosed: market.isSuspended,
					closureReason: market.marketClosureReason,
					fundingRate: hourlyFundingRate.toNumber() ?? 0,
					dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
					openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
					appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
				}
			})
		}
		if (activeFilter === 'defi') {
			let filterOutArray = [
				'YFI',
				'MKR',
				'AAVE',
				'COMP',
				'GMX',
				'INJ',
				'LINK',
				'RPL',
				'FXS',
				'UNI',
				'BAL',
				'DYDX',
				'LDO',
				'UMA',
				'SUSHI',
				'KNC',
				'PERP',
				'CRV',
				'1INCH',
				'PYTH',
				'TRB',
				'GRT',
				'JTO',
				'JUP',
				'CVX',
			]
			const getForexMarket = markets
				.filter((m) => filterOutArray.includes(m.asset))
				.sort((a, b) =>
					getBasePriceRateInfo(b.asset)
						?.price.sub(getBasePriceRateInfo(a.asset)?.price ?? 0)
						.gt(0)
						? 1
						: -1
				)

			const defiMarkets = getForexMarket
				.filter((m) => favMarkets.includes(m.asset))
				.concat(getForexMarket.filter((m) => !favMarkets.includes(m.asset)))

			return defiMarkets.map((market) => {
				const pastPrice = getPastPrice(market.asset)
				const basePriceRate = getBasePriceRateInfo(market.asset)

				const change =
					basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
						? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
						: ZERO_WEI
				const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

				return {
					value: market.asset,
					label: convertMarketName(getMarketName(market.asset)),
					asset: market.asset,
					key: market.marketKey,
					description: getSynthDescription(market.asset, t),
					priceNum: basePriceRate?.price.toNumber() ?? 0,
					price: formatDollars(basePriceRate?.price ?? '0', {
						suggestDecimalsForAsset: market.asset,
					}),
					change: change.toNumber(),
					priceDirection: basePriceRate?.change ?? null,
					isMarketClosed: market.isSuspended,
					closureReason: market.marketClosureReason,
					fundingRate: hourlyFundingRate.toNumber() ?? 0,
					dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
					openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
					appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
				}
			})
		}

		return sortedMarkets.map((market) => {
			const pastPrice = getPastPrice(market.asset)
			const basePriceRate = getBasePriceRateInfo(market.asset)

			const change =
				basePriceRate && pastPrice?.rate && basePriceRate.price.gt(0)
					? wei(basePriceRate.price).sub(pastPrice?.rate).div(basePriceRate.price)
					: ZERO_WEI
			const hourlyFundingRate = market.currentFundingRate.mul(wei(fundingHours))

			return {
				value: market.asset,
				label: convertMarketName(getMarketName(market.asset)),
				asset: market.asset,
				key: market.marketKey,
				description: getSynthDescription(market.asset, t),
				priceNum: basePriceRate?.price.toNumber() ?? 0,
				price: formatDollars(basePriceRate?.price ?? '0', {
					suggestDecimalsForAsset: market.asset,
				}),
				change: change.toNumber(),
				priceDirection: basePriceRate?.change ?? null,
				isMarketClosed: market.isSuspended,
				closureReason: market.marketClosureReason,
				fundingRate: hourlyFundingRate.toNumber() ?? 0,
				dailyVolume: futuresVolumes[market.marketKey]?.volume.toNumber() ?? 0,
				openInterest: market.openInterest.longUSD.add(market.openInterest.shortUSD).toNumber(),
				appMaxLeverage: market?.appMaxLeverage?.toNumber() ?? 0,
			}
		})
	}, [search, futuresMarkets, favMarkets, getPastPrice, getBasePriceRateInfo, t, activeFilter])

	const handleSort = useCallback(
		(
			sortBy: string,
			priceDirection?: string,
			changeDirection?: string,
			opDirection?: string,
			frDirection?: string,
			volumeDirection?: string
		) => {
			// Sort by Price
			if (sortBy === SortMethod.SortByPrice) {
				return priceDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.priceNum - a.priceNum)
					: priceDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.priceNum - b.priceNum)
					: [...options].sort((a, b) => b.priceNum - a.priceNum)
			}

			// Sort by Change
			else if (sortBy === SortMethod.SortByChange) {
				return changeDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.change - a.change)
					: changeDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.change - b.change)
					: [...options].sort((a, b) => b.change - a.change)
			}

			// Sort by Volume
			else if (sortBy === SortMethod.SortByVolume) {
				return volumeDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.dailyVolume - a.dailyVolume)
					: volumeDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.dailyVolume - b.dailyVolume)
					: [...options].sort((a, b) => b.dailyVolume - a.dailyVolume)
			}

			// Sort by Open Interest
			if (sortBy === SortMethod.SortByOP) {
				return opDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.openInterest - a.openInterest)
					: opDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.openInterest - b.openInterest)
					: [...options].sort((a, b) => b.openInterest - a.openInterest)
			}

			// Sort by Funding Rate
			if (sortBy === SortMethod.SortByFR) {
				return frDirection === SortOrder.Descending
					? [...options].sort((a, b) => b.fundingRate - a.fundingRate)
					: frDirection === SortOrder.Ascending
					? [...options].sort((a, b) => a.fundingRate - b.fundingRate)
					: [...options].sort((a, b) => b.fundingRate - a.fundingRate)
			} else {
				return options
			}
		},
		[options]
	)

	const marketData = useMemo(() => {
		return handleSort(
			sortMethod,
			priceDirection,
			changeDirection,
			opDirection,
			frDirection,
			volumeDirection
		)
	}, [
		sortMethod,
		priceDirection,
		changeDirection,
		opDirection,
		frDirection,
		volumeDirection,
		handleSort,
	])

	if (
		walletAddress &&
		!isL2 &&
		openModal !== 'futures_smart_margin_socket' &&
		openModal !== 'futures_deposit_withdraw_smart_margin'
	) {
		return (
			<Container>
				<FuturesUnsupportedNetwork />
			</Container>
		)
	}

	return (
		<Container>
			<TradeContainer>
				<FilterContainer>
					<TabsContainer>
						<FilterButton
							inline={true}
							title={'All'}
							active={activeFilter === 'all' ? true : false}
							badgeCount={0}
							disabled={true}
							onClick={() => setActiveFilter('all')}
						/>
						<FilterButton
							inline={true}
							title={'Forex'}
							active={activeFilter === 'forex' ? true : false}
							badgeCount={0}
							disabled={true}
							onClick={() => setActiveFilter('forex')}
						/>
						<FilterButton
							inline={true}
							title={'DeFi'}
							active={activeFilter === 'defi' ? true : false}
							badgeCount={0}
							disabled={true}
							onClick={() => setActiveFilter('defi')}
						/>
					</TabsContainer>
					<ActionContainer>
						<FilterIconDiv filterSelected={filter} onClick={() => setFilter(!filter)}>
							<FilterIcon width={22} height={22} />
						</FilterIconDiv>
						{filter && (
							<FilterMenu ref={ref}>
								<SortBox>
									<SortHeader>Sort By</SortHeader>
									<div>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.Favorites)
												handleSort(sortMethod)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.Favorites} />
											<FilterLabel>Favorites</FilterLabel>
										</Filter>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.SortByPrice)
												handleSort(sortMethod, priceDirection)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.SortByPrice} />
											<FilterLabel>Price</FilterLabel>
										</Filter>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.SortByVolume)
												handleSort(sortMethod, volumeDirection)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.SortByVolume} />
											<FilterLabel>24h Volume</FilterLabel>
										</Filter>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.SortByChange)
												handleSort(sortMethod, changeDirection)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.SortByChange} />
											<FilterLabel>24h Change</FilterLabel>
										</Filter>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.SortByOP)
												handleSort(sortMethod, opDirection)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.SortByOP} />
											<FilterLabel>Open Interest</FilterLabel>
										</Filter>
										<Filter
											onClick={() => {
												setSortMethod(SortMethod.SortByFR)
												handleSort(sortMethod, frDirection)
											}}
										>
											<FilterInput selected={sortMethod === SortMethod.SortByFR} />
											<FilterLabel>Funding Rate</FilterLabel>
										</Filter>
									</div>
								</SortBox>
								{sortMethod === SortMethod.SortByPrice && (
									<SortBox>
										<SortHeader>Direction</SortHeader>
										<div>
											<Filter onClick={() => setPriceDirection(SortOrder.Descending)}>
												<FilterInput selected={priceDirection === SortOrder.Descending} />
												<FilterLabel>Decreasing</FilterLabel>
											</Filter>
											<Filter onClick={() => setPriceDirection(SortOrder.Ascending)}>
												<FilterInput selected={priceDirection === SortOrder.Ascending} />
												<FilterLabel>Increasing</FilterLabel>
											</Filter>
										</div>
									</SortBox>
								)}
								{sortMethod === SortMethod.SortByVolume && (
									<SortBox>
										<SortHeader>Direction</SortHeader>
										<div>
											<Filter onClick={() => setVolumeDirection(SortOrder.Descending)}>
												<FilterInput selected={volumeDirection === SortOrder.Descending} />
												<FilterLabel>Decreasing</FilterLabel>
											</Filter>
											<Filter onClick={() => setVolumeDirection(SortOrder.Ascending)}>
												<FilterInput selected={volumeDirection === SortOrder.Ascending} />
												<FilterLabel>Increasing</FilterLabel>
											</Filter>
										</div>
									</SortBox>
								)}
								{sortMethod === SortMethod.SortByChange && (
									<SortBox>
										<SortHeader>Direction</SortHeader>
										<div>
											<Filter onClick={() => setChangeDirection(SortOrder.Descending)}>
												<FilterInput selected={changeDirection === SortOrder.Descending} />
												<FilterLabel>Decreasing</FilterLabel>
											</Filter>
											<Filter onClick={() => setChangeDirection(SortOrder.Ascending)}>
												<FilterInput selected={changeDirection === SortOrder.Ascending} />
												<FilterLabel>Increasing</FilterLabel>
											</Filter>
										</div>
									</SortBox>
								)}
								{sortMethod === SortMethod.SortByOP && (
									<SortBox>
										<SortHeader>Direction</SortHeader>
										<div>
											<Filter onClick={() => setopDirection(SortOrder.Descending)}>
												<FilterInput selected={opDirection === SortOrder.Descending} />
												<FilterLabel>Decreasing</FilterLabel>
											</Filter>
											<Filter onClick={() => setopDirection(SortOrder.Ascending)}>
												<FilterInput selected={opDirection === SortOrder.Ascending} />
												<FilterLabel>Increasing</FilterLabel>
											</Filter>
										</div>
									</SortBox>
								)}
								{sortMethod === SortMethod.SortByFR && (
									<SortBox>
										<SortHeader>Direction</SortHeader>
										<div>
											<Filter onClick={() => setfrDirection(SortOrder.Descending)}>
												<FilterInput selected={frDirection === SortOrder.Descending} />
												<FilterLabel>Decreasing</FilterLabel>
											</Filter>
											<Filter onClick={() => setfrDirection(SortOrder.Ascending)}>
												<FilterInput selected={frDirection === SortOrder.Ascending} />
												<FilterLabel>Increasing</FilterLabel>
											</Filter>
										</div>
									</SortBox>
								)}
								<SortBox>
									<SortHeader>Cards</SortHeader>
									<FilterCheckBox onClick={() => setDetailsCard(!detailsCard)}>
										<CheckBox checked={detailsCard} />
										<FilterLabelCard>Detailed view</FilterLabelCard>
									</FilterCheckBox>
								</SortBox>
							</FilterMenu>
						)}
						<SearchIconDiv onClick={() => setSearchOpen(true)}>
							<SearchIcon width={22} height={22} />
						</SearchIconDiv>
					</ActionContainer>
					<SearchBarContainer searchOpen={searchOpen}>
						<SearchBarNew
							autoFocus={searchOpen}
							onChange={setSearch}
							value={search}
							border={false}
							placeHolder={`e.g. “ETH” or “Ethereum”`}
							onClear={onClearSearch}
						/>
					</SearchBarContainer>
				</FilterContainer>
				<TableContainer>
					{marketData.length > 0 ? (
						!detailsCard ? (
							<>
								{marketData.map((row) => (
									<StyledTable
										onClick={() => {
											if (filter) {
												setFilter(false)
											} else {
												onSelectMarket(row.asset)
											}
										}}
										selected={router.asPath.includes(row.asset)}
									>
										<Item>
											<FlexDivCentered>
												<FavoriteIconWrapper
													onClick={(e) => {
														onSelectFav(row.asset)
														e.stopPropagation()
													}}
												>
													{favMarkets.includes(row.asset) ? (
														<StyledSelectedIcon height={22} width={22} />
													) : (
														<StyledFavoriteIcon height={22} width={22} />
													)}
												</FavoriteIconWrapper>
												<CurrencyWrapper>
													<CurrencyIcon currencyKey={row.key} width={32} height={32} />
													<Body type="span" size="large" fontSize={19}>
														{CurrencyName[row.key] || getDisplayAsset(row.asset)}
													</Body>
													<StyledButton>
														{getDisplayAsset(CustomisedTickers[row.key] || row.asset)}
													</StyledButton>
												</CurrencyWrapper>
											</FlexDivCentered>
											<BadgeContainer>
												<Body type="span" size="large" fontSize={18}>
													{row.price}
												</Body>
												<FlexLeverageDiv>
													<MarketBadge
														currencyKey={row.asset}
														isFuturesMarketClosed={row.isMarketClosed}
														futuresClosureReason={row.closureReason}
														fallbackComponent={
															<StyledChangePercent
																value={row.change}
																fontSize={14}
																decimals={2}
																className="change-pct"
															/>
														}
													/>
													<Spacer width={4} />
													<MaxLeverageButton>
														{row?.appMaxLeverage ? `${row.appMaxLeverage}×` : null}{' '}
													</MaxLeverageButton>
												</FlexLeverageDiv>
											</BadgeContainer>
										</Item>
									</StyledTable>
								))}
							</>
						) : (
							<MarketPanelDetailedView
								data={marketData}
								favMarkets={favMarkets}
								onSelectFav={onSelectFav}
								onSelectMarket={onSelectMarket}
								filter={filter}
								setFilter={setFilter}
							/>
						)
					) : (
						activeFilter === 'forex' && <NoResultDiv>Forex Pairs coming soon</NoResultDiv>
					)}
				</TableContainer>
			</TradeContainer>
		</Container>
	)
}

export default MobileTradePanel

const Container = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	height: calc(100% - 68px);
	background: ${(props) => props.theme.colors.selectedTheme.background};
	z-index: ${zIndex.DIALOG_OVERLAY};
`

const TradeContainer = styled.div`
	height: 100%;
	min-width: 100%;
	max-width: 100%;
`

const FilterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	padding: 0 16px;
	height: 68px;
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
`

const FilterIconDiv = styled.div<{ filterSelected?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	margin-left: 12px;
	border-radius: 8px;
	user-select: none;
	cursor: pointer;
	transition: all 0.15s ease-in-out !important;

	svg {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	${(props) =>
		props.filterSelected &&
		css`
			background: ${(props) => props.theme.colors.selectedTheme.button.fill};
			svg {
				path {
					stroke: ${(props) => props.theme.colors.selectedTheme.primary};
				}
			}
		`}

	${(props) =>
		props.filterSelected === false &&
		css`
			&:hover {
				background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
				svg {
					path {
						stroke: ${(props) => props.theme.colors.selectedTheme.primary};
					}
				}
			}
		`}
`

const FilterMenu = styled.div`
	filter: drop-shadow(0 0 44px rgba(23, 23, 34, 0.2));
	position: absolute;
	top: 62px;
	width: auto;
	max-width: 80%;
	right: 12px;
	border-radius: 6px;
	margin-left: 12px;
	padding: 12px 24px 12px 16px;
	z-index: 1;
	background-color: ${(props) => props.theme.colors.selectedTheme.gray3};
`
const SortBox = styled.div`
	width: 100%;
	margin-bottom: 14px;
	font-family: ${(props) => props.theme.fonts.regular};
`
const SortHeader = styled.div`
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 10px;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
`
const Filter = styled.a`
	display: flex;
	margin-top: 10px;
	cursor: pointer;
`

const FilterCheckBox = styled.div`
	display: flex;
	position: relative;
	align-items: center;
`

const CheckBox = styled.span<{ checked?: boolean }>`
	position: absolute;
	top: calc(50% - 10px);
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 4px;
	z-index: 0;
	cursor: pointer;
	background-color: ${(props) => props.theme.colors.selectedTheme.fill};
	&:hover {
		outline: 0.5px solid ${(props) => props.theme.colors.selectedTheme.outline};
	}

	${(props) =>
		props.checked &&
		css`
			&:after {
				position: absolute;
				content: '';
				top: 4px;
				left: 7px;
				width: 5px;
				height: 8px;
				border: solid ${(props) => props.theme.colors.selectedTheme.primary};
				border-width: 0 2px 2px 0;
				border-radius: 1px;
				opacity: 1;
				transform: rotate(40deg) scale(1);
				transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
			}
		`}
`

const FilterInput = styled.div<{ selected?: boolean }>`
	display: flex;
	position: relative;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.selectedTheme.table.fill};
	&:hover {
		outline: 0.5px solid ${(props) => props.theme.colors.selectedTheme.gray2};
	}

	${(props) =>
		props.selected &&
		css`
			&:before {
				content: '';
				position: absolute;
				top: 5px;
				left: 5px;
				height: 14px;
				width: 14px;
				border-radius: 50%;
				background-color: ${(props) => props.theme.colors.selectedTheme.button.text.lightWhite};
			}
		`}
`
const FilterLabel = styled.div`
	font-size: 16px;
	line-height: 20px;
	margin-left: 10px;
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
`

const FilterLabelCard = styled(FilterLabel)`
	display: flex;
	align-items: center;
	margin-left: 0px;
	padding-left: 30px;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
`

const SearchIconDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	min-width: var(40px);
	margin-left: 12px;

	svg {
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.gray};
		}
	}

	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.primary};
			}
		}
	}
`

const SearchBarContainer = styled.div<{ searchOpen: boolean }>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	transition: min-width 0.45s cubic-bezier(0.16, 1, 0.3, 1);
	margin-left: ${(props) => (props.searchOpen ? '24px' : '0')};
	min-width: ${(props) => (props.searchOpen ? '100%' : '0')};
	max-width: ${(props) => (props.searchOpen ? 'none' : '0')};
	opacity: ${(props) => (props.searchOpen ? '1' : '0')};
`

const TabsContainer = styled.div`
	display: flex;
	margin-right: auto;
	gap: 12px;
`

const ActionContainer = styled.div`
	display: flex;
	margin-right: 12px;
`

const FilterButton = styled(TabFilterButton)`
	height: 36px;
	min-height: 36px;
	padding: 0px 12px;
`

const TableContainer = styled.div`
	height: calc(100% - 68px);
	overflow-y: auto;
`

const StyledTable = styled.div<{ selected?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 72px;
	padding: 0 20px 0px 10px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.selectedTheme.primary};
	border-bottom: ${(props) => props.theme.colors.selectedTheme.border};
	background: ${(props) =>
		props.selected
			? props.theme.colors.selectedTheme.fill
			: props.theme.colors.selectedTheme.newTheme.containers.primary.background};
`

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 54px;
	width: 100%;
`

const FavoriteIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px !important;
	height: 32px;
	border-radius: 16px;
	&:hover {
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

const StyledSelectedIcon = styled(FavoriteIcon)`
	path {
		fill: #ffb648;
		stroke: #ffb648;
	}
`

const StyledFavoriteIcon = styled(FavoriteIcon)`
	path {
		stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
	}

	&:hover {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		}
	}
`

const CurrencyWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	line-height: 24px;
	margin-left: 4px;
`

const StyledButton = styled.div`
	display: inline-flex;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	padding: 2px 4px;
	margin-left: 6px;
	border-radius: 2px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.text.value};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
`

const BadgeContainer = styled.div`
	display: grid;
	justify-items: end;
	grid-gap: 4px;
	gap: 4px;
	margin-left: 4px;
`

const StyledChangePercent = styled(ChangePercent)`
	font-family: ${(props) => props.theme.fonts.regular};
`

const NoResultDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	margin: 24px;
	font-size: 15px;
	border-radius: 8px;
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
`
